header.shepherd-header {
  background-color: #fff !important;
  padding: 10px !important;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  padding: 16px 16px 16px 16px !important;
}

.example-step-extra-class.shepherd-element[aria-labelledby=step-8-label] {
  margin-top: -9px !important;
}

.example-step-extra-class.shepherd-element[aria-labelledby=step-9-label] {
  margin-top: -9px !important;
}

footer.shepherd-footer {
  background-color: #fff !important;
  justify-content: flex-start;
  gap: 10px;
}

.shepherd-button {
  background-color: var(--theme-base-color) !important;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.shepherd-button-secondary {
  background-color: var(--theme-base-color) !important;
  color: #fff !important;
}

.shepherd-button:hover {
  background-color: #555;
}

.shepherd-cancel-icon {
  color: rgba(0, 0, 0, 0.75) !important;
}

.shepherd-text p {
  color: black;
  padding: 0 !important;
}

.shepherd-title h3 {
  font-size: 18.5px;
  margin: 0 !important;
  padding: 0 !important;
  color: black !important;
  text-align: center !important;
}

.shepherd-text {
  padding: 0 20px 16px !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.shepherd-element {
  background-color: rgb(255, 255, 255);
  margin-top: 11px !important;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  opacity: 0.7 !important;
}

.shepherd-element.recipe-categories[aria-labelledby=step-3-label][data-popper-placement^=top] {
  top: 546.812px !important;
  margin-bottom: 6px !important;
}

.filter_class.shepherd-enabled {
  padding: 5px 15px !important;
}

.example-step-extra-class.shepherd-enabled.shepherd-element[aria-labelledby=step-1-label], [aria-labelledby=step-9-label] {
  margin-left: 1% !important;
}

.example-step-extra-class.shepherd-enabled.shepherd-element[aria-labelledby=step-10-label], [aria-labelledby=step-3-label] {
  margin-left: -1% !important;
}

.recipe-slideout-tour.shepherd-element[aria-labelledby=step-3-label] {
  margin-left: 0% !important;
}

.shopping-tour.shepherd-element[aria-labelledby=step-9-label] {
  margin-left: 0% !important;
}

.shopping-tour.shepherd-element {
  box-sizing: border-box;
  max-width: 360px;
}

.shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
  left: 86% !important;
  margin: 10px auto;
  right: 0;
  margin-bottom: 20px !important;
}

.shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-8-label] {
  margin-left: -1% !important; /* Adjust the percentage as needed */
  margin-right: -1% !important; /* Adjust the percentage as needed */
}

.shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-4-label] {
  margin-left: 1% !important; /* Adjust the percentage as needed */
  margin-right: -1% !important; /* Adjust the percentage as needed */
}

.shepherd-enabled.shepherd-element[aria-labelledby=step-11-label][data-popper-placement^=top] {
  top: 21.3px !important;
}

.recipe-slideout-tour.shepherd-element {
  box-sizing: border-box;
  margin: 10px auto;
  margin-bottom: 20px !important;
  max-width: 360px;
}

.recipe-slideout-tour.shepherd-has-title.shepherd-element[aria-labelledby=show-tour-action-label] {
  position: fixed !important;
  left: 14% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

@media (max-width: 1700px) {
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    transform: translate(-72%, -50%) !important;
  }
  .recipe-slideout-tour.shepherd-has-title.shepherd-element[aria-labelledby=show-tour-action-label] {
    left: 19% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
@media (max-width: 1300px) {
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    transform: translate(-76%, -50%) !important;
  }
}
@media (max-width: 1200px) {
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    transform: translate(-83%, -50%) !important;
  }
}
@media (max-width: 1024px) {
  .example-step-extra-class.shepherd-element[aria-labelledby=step-7-label] {
    margin-left: -1% !important;
  }
  .example-step-extra-class.shepherd-element[aria-labelledby=step-6-label] {
    margin-left: 1% !important;
  }
  .example-step-extra-class.shepherd-enabled.shepherd-element[aria-labelledby=step-11-label] {
    margin-left: -1% !important;
  }
  .coupons-tour.shepherd-element[aria-labelledby=step-2-label] {
    margin-left: -1% !important;
  }
  .recipe-categories.shepherd-element[aria-labelledby=step-5-label] {
    margin-left: -1% !important;
  }
  .recipe-categories.shepherd-element[aria-labelledby=step-4-label] {
    margin-left: 1% !important;
  }
  .recipe-slideout-tour.shepherd-has-title.shepherd-element[aria-labelledby=show-tour-action-label] {
    left: 30% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
@media (max-width: 850) {
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    transform: translate(-15%, -50%) !important;
  }
}
@media (max-width: 767px) {
  .shepherd-element {
    box-sizing: border-box;
    margin: 15px auto;
    max-width: 300px;
  }
  .recipe-slideout-tour.shepherd-element {
    box-sizing: border-box;
    margin: 10px auto;
    margin-bottom: 20px !important;
    max-width: 300px;
  }
  .shepherd-text {
    padding: 0 8px 8px !important;
    font-size: 12px !important;
  }
  .shepherd-has-title .shepherd-content .shepherd-header {
    padding: 10px !important;
  }
  .shepherd-button {
    padding: 4px 8px !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .example-step-extra-class.shepherd-enabled.shepherd-element[aria-labelledby=step-8-label] {
    margin-left: 1% !important;
  }
  .recipe-slideout-tour.shepherd-has-title.shepherd-element[aria-labelledby=show-tour-action-label] {
    transform: translate(-7%, -50%) !important;
  }
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    bottom: 60px !important;
    top: auto !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    left: 34% !important;
    right: 50% !important;
    position: fixed !important;
  }
  .example-step-extra-class.shepherd-element[aria-labelledby=step-7-label][data-popper-placement^=bottom] > .shepherd-arrow {
    bottom: -8px;
    top: unset;
    left: 46% !important;
  }
  .shopping-tour.shepherd-element {
    box-sizing: border-box;
    max-width: 300px;
  }
  .shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-8-label], [aria-labelledby=step-9-label], [aria-labelledby=step-10-label] {
    margin-left: -1% !important; /* Adjust the percentage as needed */
    margin-right: -1% !important; /* Adjust the percentage as needed */
  }
  .shepherd-text {
    padding: 0 20px 16px !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}
@media (max-width: 650px) {
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    transform: translate(-108%, -50%) !important;
  }
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    bottom: 55px !important;
    left: 29% !important;
    position: fixed !important;
  }
}
@media screen and (max-width: 576px) {
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    left: 135px !important;
    right: 0 !important;
    bottom: 74px !important;
  }
  .shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-8-label], [aria-labelledby=step-9-label], [aria-labelledby=step-10-label], [aria-labelledby=step-12-label] {
    margin-left: -1% !important; /* Adjust the percentage as needed */
    margin-right: -1% !important; /* Adjust the percentage as needed */
  }
  .shopping-tour.shepherd-element {
    box-sizing: border-box; /* Include padding and border in the width calculation */
    margin: 15px auto;
    max-width: 300px;
  }
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    left: 81% !important;
    margin: 10px auto;
    right: 0;
    margin-bottom: 20px !important;
  }
  .shepherd-text {
    padding: 0 8px 8px !important;
    font-size: 12px;
  }
  .shepherd-has-title .shepherd-content .shepherd-header {
    padding: 10px !important;
  }
  .shepherd-button {
    padding: 4px 8px !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .recipe-slideout-tour.shepherd-has-title.shepherd-element[aria-labelledby=show-tour-action-label] {
    transform: translate(-13%, -50%) !important;
  }
}
@media (max-width: 500px) {
  .shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-5-label], [aria-labelledby=step-4-label], [aria-labelledby=step-8-label], [aria-labelledby=step-9-label], [aria-labelledby=step-10-label] {
    margin-left: -1% !important; /* Adjust the percentage as needed */
    margin-right: -1% !important; /* Adjust the percentage as needed */
  }
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    left: 90px !important;
    right: 0 !important;
    bottom: 74px !important;
  }
}
@media (max-width: 453px) {
  .shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-12-label] {
    margin-left: 1% !important; /* Adjust the percentage as needed */
    margin-right: -1% !important; /* Adjust the percentage as needed */
  }
}
@media (max-width: 475px) {
  .recipe-slideout-tour.shepherd-has-title.shepherd-element[aria-labelledby=show-tour-action-label] {
    transform: translate(-22%, -50%) !important;
  }
}
@media only screen and (max-width: 420px) {
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    left: 67px !important;
    right: 0 !important;
    bottom: 74px !important;
  }
  .dark-green-text .shepherd-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-5-label], [aria-labelledby=step-4-label], [aria-labelledby=step-8-label], [aria-labelledby=step-9-label], [aria-labelledby=step-10-label] {
    margin-left: -1% !important;
  }
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    left: 91% !important;
    margin: 10px auto;
    right: 0;
    margin-bottom: 20px !important;
  }
  .recipe-slideout-tour.shepherd-element[aria-labelledby=step-1-label] {
    margin-left: -1% !important;
  }
}
@media (max-width: 375px) {
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    left: 45px !important;
    right: 0 !important;
    bottom: 68px !important;
  }
  .example-step-extra-class.shepherd-element[aria-labelledby=step-5-label] {
    margin-left: -1% !important;
  }
  .example-step-extra-class.shepherd-element[aria-labelledby=step-6-label] {
    margin-left: 1% !important;
  }
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    left: 97% !important;
    margin: 10px auto;
    right: 0;
    margin-bottom: 20px !important;
  }
  .shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-4-label], [aria-labelledby=step-3-label], [aria-labelledby=step-4-label], [aria-labelledby=step-8-label], [aria-labelledby=step-9-label], [aria-labelledby=step-10-label] {
    margin-left: -1% !important; /* Adjust the percentage as needed */
    margin-right: -1% !important; /* Adjust the percentage as needed */
  }
  .shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-5-label], [aria-labelledby=step-12-label] {
    margin-left: 1% !important; /* Adjust the percentage as needed */
    margin-right: -1% !important; /* Adjust the percentage as needed */
  }
  .recipe-slideout-tour.shepherd-has-title.shepherd-element[aria-labelledby=show-tour-action-label] {
    transform: translate(-27%, -50%) !important;
  }
}
@media (max-width: 365px) {
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    left: 30px !important;
    right: 0 !important;
    bottom: 54px !important;
  }
}
@media (max-width: 361px) {
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    bottom: 60px !important;
  }
  .coupons-tour.shepherd-element[aria-labelledby=step-1-label] {
    margin-left: 1% !important;
  }
}
@media (max-width: 330px) {
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    left: 16px !important;
    bottom: 48px !important;
  }
}
@media (max-width: 305px) {
  .example-step-extra-class.shepherd-has-title[aria-labelledby=step-7-label] {
    left: 11px !important;
    bottom: 48px !important;
  }
  .shepherd-element {
    box-sizing: border-box;
    margin: 15px auto;
    max-width: 265px;
  }
  .recipe-slideout-tour.shepherd-element {
    box-sizing: border-box;
    margin: 10px auto;
    margin-bottom: 20px !important;
    max-width: 265px;
  }
  .shopping-tour.shepherd-element {
    box-sizing: border-box;
    margin: 10px auto;
    margin-bottom: 20px !important;
    max-width: 265px;
  }
  .shepherd-text {
    padding: 0 8px 8px !important;
    font-size: 12px !important;
  }
  .shepherd-has-title .shepherd-content .shepherd-header {
    padding: 6px !important;
    font-size: 11px !important;
  }
  .shepherd-button {
    padding: 4px 8px !important;
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .shepherd-title h3 {
    font-size: 17.5px !important;
  }
  .recipe-slideout-tour.shepherd-has-title.shepherd-element[aria-labelledby=show-tour-action-label] {
    transform: translate(-28.5%, -50%) !important;
  }
  .shopping-tour.shepherd-element[aria-labelledby="The Shopping List-label"] {
    left: 105% !important;
    margin: 10px auto;
    right: 0;
    margin-bottom: 20px !important;
  }
  .recipe-slideout-tour.shepherd-element[aria-labelledby=step-4-label] {
    margin-left: 1% !important;
  }
  .example-step-extra-class.shepherd-element[aria-labelledby=step-5-label] {
    margin-left: -1% !important;
  }
  .recipe-categories.shepherd-element[aria-labelledby=step-5-label] {
    margin-left: 1% !important;
  }
  .shopping-tour.shepherd-enabled.shepherd-element[aria-labelledby=step-4-label] {
    margin-left: 1% !important;
  }
  .example-step-extra-class.shepherd-enabled.shepherd-element[aria-labelledby=step-11-label] {
    margin-left: 1% !important;
  }
  .shopping-tour.shepherd-element[aria-labelledby=step-9-label] {
    margin-left: -1% !important;
  }
  .recipe-slideout-tour.shepherd-element[aria-labelledby=step-3-label] {
    margin-left: 1% !important;
  }
}